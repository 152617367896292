import * as MarkedCommentTypes from '@wix/communities-forum-client-commons/dist/src/constants/marked-comment-types';

export const getMarkedCommentLabel = (config, t) =>
  config && t
    ? {
        [MarkedCommentTypes.BEST_ANSWER]: t('comment-marked-type.best-answer'),
        [MarkedCommentTypes.SOLVED]: t('comment-marked-type.solved'),
        [MarkedCommentTypes.FEATURED]: t('comment-marked-type.featured'),
        [MarkedCommentTypes.PINNED]: t('comment-marked-type.pinned'),
        [MarkedCommentTypes.CUSTOM]: config?.customLabel,
      }[config?.type] || MarkedCommentTypes.FEATURED
    : null;

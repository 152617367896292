import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';
import {
  CARD_BACKGROUND_COLOR,
  CARD_BORDER_COLOR,
  CARD_BORDER_WIDTH,
  POST_TITLE_COLOR,
  POST_TITLE_FONT,
  WIDGET_BACKGROUND_COLOR,
  WIDGET_BORDER_RADIUS,
  LIST_LAYOUT_ID,
  LIST_NUMBER_OF_POSTS,
  IS_LIST_POST_NUMBERS_ENABLED,
} from '@wix/communities-forum-client-commons/dist/src/constants/wix-params-rpw';
import { LAYOUT_ID_DEFAULT } from '../../recent-posts-widget/constants/layouts';

export type IStylesParams = {
  cardBorderColor: StyleParamType.Color;
  postTitleFont: StyleParamType.Font;
  postTitleColor: StyleParamType.Color;
  cardBorderWidth: StyleParamType.Number;
  widgetBorderRadius: StyleParamType.Number;
  cardBackgroundColor: StyleParamType.Color;
  widgetBackgroundColor: StyleParamType.Color;
  layoutOption: StyleParamType.Number;
  socialStatus: StyleParamType.Boolean;
  numberOfPosts: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  cardBorderColor: {
    key: CARD_BORDER_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  postTitleFont: {
    key: POST_TITLE_FONT,
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title'),
  },
  postTitleColor: {
    key: POST_TITLE_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  cardBorderWidth: {
    key: CARD_BORDER_WIDTH,
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  widgetBorderRadius: {
    key: WIDGET_BORDER_RADIUS,
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  cardBackgroundColor: {
    key: CARD_BACKGROUND_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  widgetBackgroundColor: {
    key: WIDGET_BACKGROUND_COLOR,
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.5),
  },
  layoutOption: {
    key: LIST_LAYOUT_ID,
    type: StyleParamType.Number,
    getDefaultValue: () => LAYOUT_ID_DEFAULT,
  },
  socialStatus: {
    key: IS_LIST_POST_NUMBERS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  numberOfPosts: {
    key: LIST_NUMBER_OF_POSTS,
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  },
});
